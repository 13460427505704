<template>
   <!-- 第一层弹窗 -->
  <el-dialog
  title="资源资产流转审批"
  append-to-body
  :visible.sync="dialogVisible3"
  width="80%"
  :before-close="handleClose3">
  <div class="Approve">

<!-- <h3>资源资产流转审批</h3> -->
<!-- 列表搜索条件， -->
<el-form :inline="true">
  <el-form-item label="成交时间：">
    <el-date-picker
      v-model="form.startTime"
      placeholder="选择开始日期"
      value-format="yyyy-MM-dd"
      clearable
    >
    </el-date-picker>
    至
    <el-date-picker
      v-model="form.endTime"
      placeholder="选择结束日期"
      align="right"
      value-format="yyyy-MM-dd"
      clearable
    >
    </el-date-picker>
  </el-form-item>
  <el-form-item label="流出方">
    <el-input
      style="width: 190px"
      v-model="form.crf"
      placeholder="请输入"
    >
    </el-input>
  </el-form-item>

  <!-- <el-form-item label="交易类型">
    <el-select style="width: 190px" v-model="form.cqlxbh">
      <el-option
        v-for="item in typeOptions"
        :key="item.label"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
  </el-form-item> -->
  <!-- <el-form-item label="审批号">
    <el-input
      style="width: 190px"
      v-model="form.processNumber"
      placeholder="请输入"
    >
    </el-input>
  </el-form-item> -->
  <div class="button" style="display: inline-block; margin: 0">
    <el-form-item>
      <el-button class="query-btn" type="primary" @click="search"
        >查询</el-button
      >
      <el-button class="query-btn" type="primary" @click="clear"
        >清空</el-button
      >
      <!-- <el-button class="query-btn" type="primary" @click="query"
        >下载年度流转审批表</el-button
      >
      <el-button class="query-btn" type="primary" @click="clear"
        >下载本年度补贴公告公示</el-button
      > -->
    </el-form-item>
  </div>
</el-form>
  <div class="tools">
      <el-button type="success"  @click="submitApproval()">提交审批</el-button>
  </div>
  <div class="table-content">
    <table-page
       ref="approvalTable"
      :isShow="isShow"
      :data="approvalVoList"
      :defaultSelectList="defaultSelectList"
      :columns="columns"
      :currentPage="query.pnum"
      :pageSize="query.psize"
      :total="total"
      :loading="loading"
      :border="true"
      @selectionChange="handleSelection"
      @handSelectAll="handSelectAll"
      @handSelect="handSelect"
    >
    <template slot="xmmc" slot-scope="scope">
      <span @click="showDetail(scope.row.xmbh)" style=" color: blue;">{{ scope.row.xmmc }}</span>
    </template>
    <template slot="lznx" slot-scope="scope">
      <span>{{ scope.row.lznx }}年</span>
    </template>
      <template slot="flowAreaMu" slot-scope="scope">
        <span>{{ scope.row.flowAreaMu }}亩</span>
      </template>
      <template slot="cjzj" slot-scope="scope">
        <span>{{ formatCjzj(scope.row.cjzj) }}</span>
      </template>
    </table-page>
  </div>

  <!-- 第二层弹窗  z-index="1000"-->
  <el-dialog   append-to-body width="80%" title="如下资源资产交易记录缺少合同归档文件，请上传完成后在提交审批" :visible.sync="outerVisible">
    <!-- 没有集体负责人的  -->
    <div v-if="tableData2.length" style="margin-top:20px;margin-bottom:10px; color:red ">以下地区村集体经济组织暂未认证，请补充村集体负责任人电话或删除对应项目审批！</div>
    <TablePageTwo
    v-if="tableData2.length"
    :data="tableData2"
    :columns="columns2"
    :currentPage="query2.pnum2"
    :pageSize="query2.psize2"
    :total="total2"
    :loading="loading2"
    :border="true">
    <template slot="xmbh" slot-scope="scope" >
      <span  v-for="(item,index) in scope.row.list" :key="index" >
        {{ item.xmbh }}
      </span>

    </template>
    <template slot="xmdz" slot-scope="scope" >
      <span>
            {{ scope.row.list.length > 0? scope.row.list[0].xmdz :'' }}
      </span>

    </template>
    <template slot="xmmc" slot-scope="scope">
      <span style=" color: blue;" v-for="(item,index) in scope.row.list" :key="index"  @click="showDetail(item.xmbh)">
            {{ item.xmmc }} <br>
      </span>
    </template>
    <template slot="operation" slot-scope="scope">
      <el-button type="text" size="small" @click="noUp(scope.row)">  <el-button   type="primary">{{scope.row.operation? '已取消' :'暂不提交'}}</el-button></el-button>
    </template>
    <template slot="phone" slot-scope="scope">
      <el-input  @input="changePhone(scope.row)" v-model="scope.row.phone"  :disabled="scope.row.operation"  placeholder="请输入手机号"></el-input>
      <span style="color:red">{{scope.row.validateTip}}</span>
    </template>
    </TablePageTwo>
    <div v-if="tableData3.length" style="margin-top:20px;margin-bottom:10px; color:red ">	以下资源资产流转缺少合同归档文件，请补充后提交审批！</div>
    <!-- 有集体负责人的 -->
    <TablePageTwo
    v-if="tableData3.length"
    :data="tableData3"
    :columns="columns3"
    :currentPage="query3.pnum"
    :pageSize="query3.psize"
    :total="total2"
    :loading="loading2"
    :border="true">
    <template slot="xmmc" slot-scope="scope">
      <span  @click="showDetail(scope.row.xmbh)" style=" color: blue;">{{ scope.row.xmmc }}</span>
    </template>
    <template slot="flowAreaMu" slot-scope="scope">
      <span>{{ scope.row.flowAreaMu }}亩</span>
    </template>
    <template slot="cjzj" slot-scope="scope">
      <span>{{ scope.row.cjzj }}元</span>
    </template>
    <template slot="lznx" slot-scope="scope">
      <span>{{ scope.row.lznx }}年</span>
    </template>
    <template slot="operation" slot-scope="scope">
      <el-button v-if="!scope.row.operation" type="primary" size="small"  @click="upLook(scope.row)">上传合同照片</el-button>
      <el-button type="primary" size="small"  @click="noPut(scope.row)">{{scope.row.operation? '已取消' :'暂不提交'}}</el-button>
    </template>
    </TablePageTwo>
<!-- 第三层弹窗  z-index="9999999"-->
    <el-dialog
    append-to-body
      title="合同照片上传"
      :visible.sync="innerVisible"
     >
      <!-- 图片上传 -->
     <el-upload class="upload-demo" ref="AvatarUpload" action=""
     :accept="'.jpg, .JPG, .png, .PNG, .jpeg, .JPEG, WebP, .pdf'" list-type="picture-card" :auto-upload="false"
     :file-list="fileList" :on-change="upLoadImg"  multiple>
     <i slot="default" class="el-icon-plus"></i>
     <div slot="file" slot-scope="{ file }">
       <el-image ref="preview" style="height: 115px"
       class="el-upload-list__item-thumbnail"
       :src="file.url"
       :preview-src-list="innerTableList"
       alt=""
       :initial-index="BigImgIndex"
        />
       <span>{{ file.name }}</span>
       <span class="el-upload-list__item-actions">
         <span v-if="!file.isShow " class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
           <i class="el-icon-zoom-in"></i>
         </span>
         <span  class="el-upload-list__item-delete" @click="handleDownload(file)">
           <i class="el-icon-download"></i>
         </span>
         <span  class="el-upload-list__item-delete" @click="handleRemove(file)">
           <i class="el-icon-delete"></i>
         </span>
       </span>
     </div>
   </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button style="margin-top: 20px" @click="back">关闭</el-button>
        <el-button :loading="loadingwxf" type="primary"  @click="submit()">{{ loadingwxf ? '上传中...' : '保存' }}</el-button>
      </div>

    </el-dialog>
    <div slot="footer" class="dialog-footer">
      <el-button @click="outerVisible = false">关闭</el-button>
      <el-button  type="primary" @click="again()">提交审批</el-button>
    </div>
  </el-dialog>

</div>
</el-dialog>
</template>
<script>
const Query = [
  {
    name: "title",
    label: "提交时间",
    componentType: "input",
    inputType: "",
    placeholder: "请选择",
    width: "160px",
    dataOptions: [],
  },
  {
    name: "departName",
    label: "状态：",
    componentType: "input",
    placeholder: "请选择",
    width: "140px",
    inputType: "",
    dataOptions: [],
  },

  {
    name: "status",
    label: "交易类型",
    componentType: "select",
    placeholder: "请选择",
    width: "180px",
    dataOptions: [
      {
        name: "全部",
        value: 0,
      },
      {
        name: "转让",
        value: -1,
      },
      {
        name: "出租",
        value: 1,
      },
      {
        name: "入股",
        value: 2,
      },
    ],
  },
  {
    name: "departName",
    label: "产权类型：",
    componentType: "input",
    placeholder: "请选择",
    width: "140px",
    inputType: "",
    dataOptions: [],
  },

];
import { uploadFile } from "@/api/fileApi";

// 图片下载
import { download } from "@/api/ChargeUp";
import { mapGetters,mapMutations } from "vuex";
import { uploadContract, selectContractPic } from "@/api/add";
import { selectNoApproval,insertApprovar } from "@/api/FlowApproval.js";
import { spColumn,sqProps,sqProps2  } from "../tableColumn";
import FormQuery from "@/components/form/form-search";
import TablePage from "@/components/table/Approve-page";
import TablePageTwo from '@/components/table/CallForBids-page'
export default {
	components: {

	},
	data() {
		return {
      // validateTip: "",//手机号校验提示语
      dialogVisible3: true,
      underApprovalList:[],//审批中的项目列表
      Query: Query,
      approvalVoList:[],//发起审批的列表所有数据的list
      approvalNowYearsList:[],//发起审批的列表本年度的list
      defaultSelectList:[],//选中的列表
      isShow:false,
      loading: false,
      loading2: false,
      loadingwxf: false,
      columns : spColumn,
      columns2: sqProps,
      columns3:sqProps2,
      keyForm: 1,
      total: 0,
      total2: 0,
      outerVisible: false,
      innerVisible: false,
      baseUrl: window.globalUrl.BASE_API,
      InitObj:{},
      tabsInex:0,
      contractPhoto:[],  // 存储合同照片
      otherPhoto:[],  // 存储其他材料
      innerTableList: [],
      BigImgIndex:0,
      fileList: [],
      tableData3:[],
      tableData2:[],
      xmbhList:[],//项目编号列表
      xmbsmList:[],//项目标识码列表
      leadingOfficialList:[],//集体负责人列表
      // isSubmitting : false,//第一次提交状态
      isProcessing: false, //第二次提交状态
      form:{
        startTime:'',
        endTime:'',
        crf:'',
        // cqlxbh:''
      },
      query: {
        current: 1,
        size: 10,
      },
      query2: {
        pnum2: 1,
        psize2: 10,
      },
      query3: {
        pnum: 1,
        psize: 10,
      },
      tabsList:[
        {
         title:'合同照片'
        },{
          title:'其他类型'
        }
      ],
      // typeOptions: [
      //   {
      //     label: "全部",
      //     value: 'null',
      //   },
      //   {
      //     label: "资源资产备案",
      //     value: "1",
      //   },
      //   {
      //     label: "补贴申请",
      //     value: "2",
      //   },
      // ],
    }

	},
  computed: mapGetters(["identityObj"]),
  components: {
    FormQuery,
    TablePage,
    TablePageTwo
  },
	created() {
    this.selectNoApproval()
    console.log(this.identityObj,'查看this.identityObj')


	},

  methods: {

    formatCjzj(cjzj) {
      try {
        if (typeof cjzj !== 'number') {
          throw new TypeError('cjzj应为数字');
        }
        // 如果大于一万元，进行除以10000的处理，否则返回原值
        return cjzj > 10000 ? (cjzj / 10000).toFixed(2)+"万元" : cjzj.toFixed(2)+"元";
      } catch (error) {
        console.error(error.message);
        return '0.00'; // 错误时返回默认值
      }
    },
    changePhone(row){
      console.log( row.phone,'查看是否执行')
      if (!row.phone) {
      row.validateTip = '手机号不能为空';

    } else if (!/^1[3-9]\d{9}$/.test(row.phone)) {
      row.validateTip= '请输入有效的手机号';
    } else {
     row.validateTip = '';

    }
    console.log( row.validateTip,'查看校验的是什么')
    },
    noUp(obj){
      console.log(obj,'查看obj')
      obj.validateTip=''
      obj.operation=!obj.operation
      obj.phone=''
      if(this.tableData2.length){
        this.outerVisible=false
      }

      // 遍历 tableData2 中当前项的 list
      obj.list&&obj.list.forEach(subItem => {
            const currentXmbh = subItem.xmbh; // 获取当前子项的项目编号

            // 更新 tableData3 中对应项目的 operation 状态
            this.tableData3.forEach(item => {
                if (item.xmbh === currentXmbh) { // 检查项目编号是否相同
                    item.operation = obj.operation; // 将状态与 obj.operation 反向
                }
            });
        });
// 找到与 obj.list[0]相同对象的 list 在this.tableData2   中的下标，并删除包含此 list 的对象
        let newTable= this.tableData2.filter(item => {

          return !item.list.some(subItem => {
            return subItem.xmbh === obj.list[0].xmbh && subItem.xmdz === obj.list[0].xmdz;
          });
        });
        console.log(newTable,'查看newTable')
        this.tableData2 = newTable;


    },
    //不提报
    noPut(obj){
     console.log(obj,'查看obj2')
      this.$set(obj, 'operation', !obj.operation);
      console.log(obj.operation,'查看obj.operation')
    // 判断现在数据的状态
    // 取消状态删除list中的某一项，如果没有那一项之前删除整个item

     if(obj.operation){

        //禁用状态
          console.log('执行1')
          // 遍历 tableData2
          this.tableData2&&this.tableData2.forEach((item) => {
            if (item.list) {
              // 遍历当前项的 list，并删除与 obj.xmbh 相同的项
              item.list = item.list.filter(subItem => subItem.xmbh !== obj.xmbh);
            }else{

            }
          // 删除对应的 item 如果 list 为空
          this.tableData2 = this.tableData2.filter(item => item.list && item.list.length > 0);
      });

     }else{
//打开状态
      // 点击上传需要this.tableData2集体负责人的手机号增加此项
      console.log('执行2')
      // 是否需要添加新的对象
      let found = false;
      // 是否需要改变所需要的状态
      let state=false

      this.tableData2&& this.tableData2.forEach(item => {
    if (item.list) {
      // 检查 item.list 中是否存在与 obj.xmdz 相同的项
      const exists = item.list.some(subItem => subItem.xmdz === obj.xmdz);
      const exists2=item.list.some(subItem => subItem.xmbh === obj.xmbh);
      if (exists&&!exists2) {
        console.log('这里不能执行')
        // 如果存在，相应的 obj 添加到当前 item.list
        item.list.push(obj);
        found = true; // 找到匹配项
      }
      else{
        found = true;
        //特殊情况需要把this.table2中的operation取反
        state=true
      }
    }
  });

  // 如果没有找到相同的 xmdz，添加一个新的对象到 this.tableData2
  if (!found) {
    const existsInLeadingOfficialList =this.leadingOfficialList&&this.leadingOfficialList.some(official => {
      console.log(official,'查看official')
      // 检查官方列表中每个对象的 list 是否存在与 obj.xmdz 相同的项
      return official.list && official.list.some(subItem => subItem.xzqbh === obj.xzqbh);
    });
    console.log(existsInLeadingOfficialList,'查看existsInLeadingOfficialList')
    // this.tableData2.push({
    //   list: [obj], // 创建一个新的 list 包含当前 obj
    //   operation: false // 设定 operation 的初始值
    // });

    if (existsInLeadingOfficialList) {
      this.tableData2.push({
        list: [obj], // 创建一个新的 list 包含当前 obj
        operation: false // 设定 operation 的初始值
      });
    }
  }
  if(state){
    // console.log('这里需要执行11111111111111111111')
    this.tableData2 && this.tableData2.forEach(item => {
    if (item.list) {
       item.list = item.list.filter(subItem => subItem.xmbh !== obj.xmbh);
      // 使用 Array.some() 来检查是否存在相等的 xmbh
      const isHas = item.list.some((listItem) => listItem.xmbh === obj.xmbh);

      // 如果存在则更新状态
      if (isHas) {
        this.$set(item, 'operation', obj.operation);
      }
      }
    });
  }

    //  console.log(this.tableData2, '经过处理后的 tableData2');
    }


    },
    handleClose3() {
      this.$emit("closeDialog3", false);
    },
    handleSelection(selects) {
                console.log(selects);
    },  // 全选或者反选
    handSelectAll(selection) {
      this.defaultSelectList = selection;
      // console.log(selection, "全选");
    },
    //多选
    handSelect(selection) {
      this.defaultSelectList = selection;
      // console.log(selection, "多选");
    },
    //删除
    handleRemove(file, fileList) {
      //可用版本1
      // console.log(file, fileList);
      const index = this.fileList.findIndex((item) => item.uid === file.uid);
      this.fileList.splice(index, 1);
    },
    //放大
    handlePictureCardPreview(file) {
      // console.log(file,'查看file')
      this.BigImgIndex= this.fileList.findIndex(item => item.uid === file.uid);
      // console.log( this.fileList,'查看fileList')
      let abc = [];
      this.fileList.forEach((its) => {
        if (!its.isShow) {
          abc.push(its.url);
        }
      });
      this.innerTableList = abc;
      this.$nextTick(() => {
        this.$refs.preview.clickHandler();
      });
    },
        //下载
     async handleDownload(file) {
      console.log("下载");
      console.log(file);
      let itemList = file;
      console.log(this.baseUrl + "admin/file/get?ossFilePath=" + itemList.url2);

      let res = await download({
        ossFilePath:
          this.baseUrl + "admin/file/get?ossFilePath=" + itemList.url2,
      });
      if (res) {
        let blob = res;
        let ToName = itemList.name;
        const fileName = ToName;
        let downloadElement = document.createElement("a");
        let binaryData = [];
        binaryData.push(blob);
        let href = window.URL.createObjectURL(
          new Blob(binaryData, {
            type: "application/pdf||doc||docx||xls||xlsx||zip||7z",
          })
        ); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(fileName); //解码
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    },

    //清空
    clear(){
      this.form.startTime='',
      this.form.endTime=''
      this.form.crf=''
      // this.form.cqlxbh=''
      this.selectNoApproval()
    },
    //查询
    search(){
      console.log(this.form,'查看form')
      this.selectNoApproval()
    },
    // 改变回显的样式
    getViewUrl1(arr) {
      console.log('这里需要执行',arr)
      if(arr){
        arr.forEach((item) => {
        item.name = item.FilesName;
        item.url = `${this.baseUrl}admin/file/get?ossFilePath=${item.imgUrl}`;
        item.url2 = item.imgUrl;
      });
      this.fileList = arr;
      console.log(this.fileList,'查看fileList');

      }else{
        this.fileList =[]
      }

    },
      //上传的图片数组
    getViewUrl(arr) {
      let list = [];
      // console.log(arr, "查看这个arr");
      if(arr){
        arr.forEach((item) => {
        list.push({
          FilesName: item.name,
          imgUrl: item.url2,
          uid: item.uid,
        });
      });
      return JSON.stringify(list);
      }

    },
    //打开上传合同弹窗
    async  upLook(obj){
      this.fileList =[]
      this.innerVisible=true;
      this.loadingwxf = false;
      this.InitObj = obj;
      let params = {
          xmbsm: obj.xmbsm,
        };
        let res = await selectContractPic(params);
        if (res.code == 0) {
          console.log(JSON.parse(res.data.picUrl),'查看res.data.picUrl');
          this.getViewUrl1(JSON.parse(res.data.picUrl));
        } else {
         this.$message.error(res.msg);
        }


    },
    // 关闭上传弹窗
    back() {
      this.innerVisible=false
    },
    //向后端发送合同照片
   async submit(){

      let ImgUrl = this.getViewUrl(this.fileList);
      let params = {
        contractBaseInfoDtoList: [{ xmbsm: this.InitObj.xmbsm }],
        picUrl: ImgUrl,
      };
      let res = await uploadContract(params);
      if (res.code == 0) {
        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }
      this.innerVisible=false
    },
    async upLoadImg(file, fileList) {
      this.loadingwxf = true;
      if (file.name.toLowerCase().endsWith("pdf")) {
        // 修改符合条件的文件对象的 url 属性
        file.url =
          "https://tse2-mm.cn.bing.net/th/id/OIP-C.or-hk0X7YoPwI6IzdXK7sgHaHa?w=168&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7";
        file.isShow = "true";
      }

      let res = await this.upload(file, fileList);
    },
      // 上传
      async upload(file, fileList) {
      console.log("三个哈几个大概时间环刀法嘎洒",file, fileList);
      let param = new FormData();
      param.append("file", file.raw);
      let res = await uploadFile(param);

          this.loadingwxf = false;
          if (res.code == 0) {
            this.$message.success("上传成功");
          } else {
            fileList = fileList.filter((item) => item.url2 !== file.url2);
            this.$message.error("上传失败");
          }
      file.url2 = res.data.url;
      // console.log("file", file);
      this.fileList = fileList;

    },
    //发起流转审批列表
    async selectNoApproval(){
      let params={
        current:this.query.current,
        size:this.query.size,
        ...this.form,
      }

      let res= await selectNoApproval(params)
      if(res.code==0){
        // if(this.isState){
        // this.$nextTick(()=>{
          // this.submitApproval()

        // })


    // }
        this.approvalVoList=res.data.approvalVoList.records,
        this.defaultSelectList=res.data.approvalNowYearsList

      }
      console.log('查看获取到的提交列表', this.approvalList)
    },

    //提交审批
   async  submitApproval(){
   // 添加一个防止重复点击的状态标志
  //  if (this.isSubmitting) return; // 如果正在提交，则直接返回
  //   this.isSubmitting = true; // 设置为正在提交状态
  let xmbhList = [];
  let xmbsmList = [];

  this.defaultSelectList.forEach((item) => {
    xmbhList.push(item.xmbh);
  });

  this.defaultSelectList.forEach((item) => {
    xmbsmList.push(item.xmbsm);
  });

  this.xmbhList = xmbhList;
  this.xmbsmList = xmbsmList;

  let params = {
    xmbhList: xmbhList,
    xmbsmList: xmbsmList,
  };

  let res = await insertApprovar(params);
  console.log(res);
  // if(!this.isState){
  // this.selectNoApproval()
//   }else{
//  this.SET_DIGSTATE(false)
//   }

  if (res.code == 0) {
    this.$message.success({
      message: res.msg,
      offset: 85,
    });
    this.handleClose3()
  } else {
    if(res.msg == '如下项目已在审批中,请勿重复提交.'){

      let underApprovalList = []
        for (let i = 0; i < res.data.length; i++) {
          underApprovalList.push(res.data[i].xmmc)
        }
        console.log(underApprovalList,'查看underApprovalList')
        const h = this.$createElement;
        this.$msgbox({
          title: '如下项目已在审批中,请勿重复提交.',
          message: h('div', null, [

          h('ul', null,
            underApprovalList.map(item => {
              return h('li', null, item); // 为每个项目创建一个列表项
            })
          )
        ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              // instance.confirmButtonLoading = true;
              // instance.confirmButtonText = '执行中...';
              // setTimeout(() => {
                done();
              //   setTimeout(() => {
              //     instance.confirmButtonLoading = false;
              //   }, 300);
              // }, 3000);
            } else {
              done();
            }
          }
        })


    }
    else{
      this.outerVisible = true;
     this.$message.info({
      message: res.msg,
      offset: 85,
    });
    if(res.data.approvalList){
      let newData3 = res.data.approvalList?res.data.approvalList:'';
    for (let i = 0; i < newData3.length; i++) {
      Object.assign(newData3[i], { operation: false }); // 是否禁止使用
    }
    this.tableData3 = newData3;

    }
    if(res.data.groupApprovalList){
    // 需要传手机号
    const newData =res.data.groupApprovalList? Object.keys(res.data.groupApprovalList).map((key) => {
      return {
        list: res.data.groupApprovalList[key],
      };
    }):''

    for (let i = 0; i < newData.length; i++) {
      Object.assign(newData[i], { operation: false }); // 是否禁止使用
    }
    console.log(newData, '查看改变后的newData');
    this.tableData2 = newData;
    this.leadingOfficialList=JSON.parse(JSON.stringify(newData))
    }



    console.log(this.leadingOfficialList,'查看第一次leadingOfficialList')
    }

  }

  // 请求完成后，重置状态标志
  // this.isSubmitting = false;
        console.log(this.tableData2,'查看this.tabeData2')
    },

    async again(){
      // if (this.isProcessing) return; // 如果正在处理，则直接返回
      //   this.isProcessing = true; // 设置为正在处理状态

        let xmbhList = [];
        let xmbsmList = [];
        let noPutXmbhList=[];
        let nopUtXmbsmList=[];
        let phoneList = {};
      console.log(this.tableData2,'this.tableData2')
      console.log(this.tableData3,'this.tableData3')
     const extractData = (dataArray) => {
          dataArray&&dataArray.forEach((item) => {
            if (item.operation) {
              if (item.list) {
                item.list.forEach((subItem) => {
                  xmbhList.push(subItem.xmbh);
                  xmbsmList.push(subItem.xmbsm);
                });
              } else {
                xmbhList.push(item.xmbh);
                xmbsmList.push(item.xmbsm);
              }
            }else{
              if (item.list) {
                item.list.forEach((subItem) => {
                  noPutXmbhList.push(subItem.xmbh);
                  nopUtXmbsmList.push(subItem.xmbsm);
                });
              } else {
                noPutXmbhList.push(item.xmbh);
                nopUtXmbsmList.push(item.xmbsm);
              }
            }
          });
    };
    // 处理 tableData2 和 tableData3
    extractData(this.tableData2);
    extractData(this.tableData3);
    //去重
    xmbhList = [...new Set(xmbhList)];
    xmbsmList = [...new Set(xmbsmList)]


    //筛选第一次的项目编号和标识码和选择的所没有的但是筛选出来的包括暂不提交的
    let newXmbhList = this.xmbhList && this.xmbhList.filter(item => !xmbhList.includes(item));
    let newXmbsmList = this.xmbsmList && this.xmbsmList.filter(item => !xmbsmList.includes(item));
    console.log(newXmbhList,'newXmbhList')
    console.log(newXmbsmList,'newXmbhList')
    console.log(xmbhList,'xmbhList')
    console.log(xmbsmList,'xmbsmList')



    // 手机号标识
    this.tableData2 && this.tableData2.forEach((item) => {
      if (!item.operation && item.phone) {
        phoneList[item.list[0].xzqbh] = item.phone;
      }
    });

    console.log(this.tableData2, 'tableData2');

    this.tableData2 && this.tableData2.forEach((item) => {
      // 检查手机号是否为空并且operation未设置
      if (!item.operation) {
        if (!item.phone) {
          this.$set(item, 'validateTip', '手机号不能为空');
        } else {
          this.$set(item, 'validateTip', ''); // 清空提示
        }
      } else {
        this.$set(item, 'validateTip', ''); // 如果operation被设置，不显示提示
      }
    });

    // 手机号校验
    let params = {
      xmbhList: newXmbhList,
      xmbsmList: newXmbsmList,
      phone:phoneList
    };

    // 检查tableData2中的validateTip
    let hasError = this.tableData2&&this.tableData2.some(item => item.validateTip);
    if (hasError) {
      this.$message.warning('您还有未填写的项');
      // this.isProcessing = false; // 重置为未处理状态
      return;
    }
    console.log(params, '查看params');

    // 继续执行插入审批的代码
    let res;
    try {
      res = await insertApprovar(params);
      console.log(res);

      if (res.code == 0) {
        this.$message.success({
          message: res.msg,
          offset: 85,
        });
        // this.outerVisible = true;
        this.handleClose3()
        // this.outerVisible = false;
        // this.dialogVisible3=false;
        // this.$emit("handleClose3", false);

        this.outerVisible = true;
      } else {
        this.$message.info({
          message: res.msg,
          offset: 85,
        });
        for (let i = 0; i < res.data.groupApprovalList.length; i++) {
          Object.assign(res.data.groupApprovalList[i], { operation: false });
        }
        this.tableData2 = res.data.groupApprovalList;
      }
    } catch (error) {
      console.error(error);
      // this.$message.error('接口请求失败，请重试');
    } finally {
      // this.isProcessing = false; // 不论成功与否，重置状态标志
    }
    },
    showDetail(xmbh){
      console.log(this.baseUrl)
      // this.SET_DIGSTATE(true)
      console.log(xmbh,'查看xmbh')
      // https://www.xctfw.com/api/
    // 查看项目详情
    // this.$router.push({
    //       name: "lzsp",
    //       query: {
    //         c: xhbh,
    //         isUpTyp:'before'
    //       }
    //   });
    // http://192.168.18.26:8086/#/home/lzsp?c=LSJSQ2024GD02253&approvalId=0001&isUpTyp=afterUp&workFlowId=34899ad8-9baf-11ef-a830-10b1df72393a
    let url = this.baseUrl;
    // let url="https://www.xctfw.com/api/"
    let cleanedUrl = url.replace("/api/", "/");


    window.open(cleanedUrl +"#/home/"+ "lzsp?c=" + xmbh + "&isUpTyp=before");
    // console.log(cleanedUrl +"#/home/"+ "lzsp?c=" + xmbh + "&isUpTyp=before")

    }
},



}
</script>



<style lang="less" scoped>
.Approve{
  width:100%;
  height: 100%;

}
.tabs{
  width: 100%;
  height:100%;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-around;
  .itemCls{
    display: block;
    background: #ccc;
  }
}
span{
  cursor: pointer; /* 鼠标悬停时变成小手 */
}


</style>

//流转审批
import service from "../request/index";
const baseUrl = window.globalUrl.HOME_API;

// 新版本-获取土地类型
export const selectNoApproval = (data) => {
  return service({
    url: `${baseUrl}/trade-website/cqjy/selectNoApproval`,
    method: "get",
    params: data,
  });
};
// 提交审批
export const insertApprovar = (params) => {
  console.log(params,'查看传到js里了嘛')
  return service({
    url: `${baseUrl}/trade-website/cqjy/insertApprovar`,
    method: "post",
    data: params,
  });
};
//被退回的审批列表
export const selectWithdrawList = (data) => {
  return service({
    url: `${baseUrl}/trade-website/cqjy/selectWithdrawList`,
    method: "get",
    params: data,
  });
};
// 被退回的列表重新提交
export const reSubmitApproval = (params) => {
  return service({
    url: `${baseUrl}/trade-website/cqjy/reSubmitApproval`,
    method: "post",
    data: params,
  });
};
// 被退回的项目进行上传
export const uploadApprovalContract = (params) => {
  return service({
    url: `${baseUrl}/trade-website/cqjy/uploadApprovalContract`,
    method: "post",
    data: params,
  });
};

//查看被退回的备份合同
export const selectBackUpContract = (data) => {
  return service({
    url: `${baseUrl}/trade-website/cqjy/selectBackUpContract`,
    method: "get",
    params: data,
  });
};
// 查看审批之前的其他材料
export const selectOtherMaterials = (data) => {
  return service({
    url: `${baseUrl}/trade-website/cqjy/selectOtherMaterials`,
    method: "get",
    params: data,
  });
};
// 查看审批之后的其他材料
export const selectApprovalOtherMaterials = (data) => {
  return service({
    url: `${baseUrl}/trade-website/cqjy/selectApprovalOtherMaterials`,
    method: "get",
    params: data,
  });
};
